.tag-input .tags {
  display: inline-flex;
}
.tag-input .tags .tag, .tag-input .tags .new-tag-button {
  flex: auto;
  display: inline-grid;
  border-radius: 10rem;
  color: white;
}
.tag-input .tags .tag input, .tag-input .tags .new-tag-button input {
  background: none;
  color: #fff;
}
.tag-input .tags .tag .delete-tag, .tag-input .tags .new-tag-button .delete-tag {
  grid-area: 1/3;
  height: 12px;
  margin-top: -6px;
  cursor: pointer;
}
.tag-input .tags .tag .delete-tag svg, .tag-input .tags .new-tag-button .delete-tag svg {
  position: relative;
}
.tag-input .tags .tag::after,
.tag-input .tags .tag input,
.tag-input .tags .tag textarea, .tag-input .tags .new-tag-button::after,
.tag-input .tags .new-tag-button input,
.tag-input .tags .new-tag-button textarea {
  width: auto;
  min-width: 1em;
  grid-area: 1/2;
  font: inherit;
  padding: 0;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border: none;
}
.tag-input .tags .tag::after, .tag-input .tags .new-tag-button::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}
.tag-input .tags .tag {
  padding: 0 1rem;
  background-color: #698FFF;
  font-size: 14px;
  height: 28px;
  max-width: 325px;
  border: 0;
  outline: 0;
  margin-right: 0.4rem;
  margin-bottom: 0.5rem;
}
.tag-input .tags .tag.error {
  background-color: #ff0000;
}
.tag-input .tags .new-tag-button {
  position: relative;
  height: 28px;
  width: 28px;
  background: #ccc;
  font-size: 16px;
  cursor: pointer;
}
.tag-input .tags .new-tag-button svg {
  position: absolute;
  stroke: #fff;
  width: 12px;
  height: 12px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}