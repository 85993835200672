/* Base Color and Background Mixins */
#domain-search .input-icon-group {
  grid-gap: 0;
}
#domain-search .search-button button svg {
  height: 20px !important;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
}
#domain-search .search-button button:active {
  opacity: 1;
}
#domain-search .search-button button:active svg {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
#domain-search .search-button {
  background: var(--baseColor-confirm);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#domain-search .search-button svg {
  position: relative;
  height: 20px;
  width: 20px;
}
#domain-search .search-results {
  display: grid;
  grid-gap: 1px;
}
#domain-search .search-results .single-domain {
  display: grid;
  grid-template-columns: 40px 1fr 64px;
  background: var(--baseColor-default);
  height: var(--height-row);
}
#domain-search .search-results .single-domain.available {
  cursor: pointer;
}
#domain-search .search-results .single-domain.available:active {
  opacity: 0.75;
}
#domain-search .search-results .single-domain.unavailable {
  cursor: default;
  color: #999999;
}
#domain-search .search-results .single-domain .domain-text {
  text-align: left;
  padding: var(--ui-padding-default);
  padding-left: 0 !important;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
}
#domain-search .search-results .single-domain .domain-text .url {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#domain-search .search-results .single-domain .availability-text {
  text-align: right;
  padding: var(--ui-padding-default);
}
#domain-search .search-results .single-domain .status {
  position: relative;
  height: var(--height-row);
  width: var(--height-row);
}
#domain-search .search-results .single-domain .available {
  float: left;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
}
#domain-search .search-results .single-domain .available.no {
  background-color: #f30;
}
#domain-search .search-results .single-domain .available.yes {
  background-color: var(--baseColor-confirm);
}
#domain-search .search-results .single-domain .pending {
  animation: pending 2s linear infinite;
  -moz-animation: pending 2s linear infinite; /* Firefox */
  -webkit-animation: pending 2s linear infinite; /* Safari and Chrome */
  -o-animation: pending 2s linear infinite; /* Opera */
}
@keyframes pending {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: #ccc;
  }
}
@-moz-keyframes pending {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: #ccc;
  }
}
@-webkit-keyframes pending {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: #ccc;
  }
}
@-o-keyframes pending {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #000;
  }
  100% {
    background-color: #ccc;
  }
}
#domain-search .more-actions button.show-more {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

[window-name=account-manager] .uiWindow-content #domain-search {
  margin-bottom: calc(var(--ui-padding-uiWindow) * 2);
}

[window-name=subscription-window] .uiWindow-content .domains .domain-card {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-gap: var(--element-gap);
  max-width: calc(var(--domain-manager-width) - var(--ui-padding-default-horizontal) * 2);
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-details {
  background: var(--baseColor-default);
  padding: var(--ui-padding-default);
  grid-auto-flow: row;
  height: auto;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-details .variable-info-text {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-details .variable-info-text .plain-url {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .in-use-by-placeholder {
  display: block;
  color: #999999;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-name {
  display: block;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card:hover .domain-in-use .in-use-link {
  visibility: visible;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use {
  display: block;
  color: #999999;
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 345px;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use .in-use-arrow-link a {
  display: inline-flex;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  cursor: pointer;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use .in-use-arrow-link:active {
  opacity: var(--opacity-downstate-default);
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use .in-use-arrow-link a:active {
  opacity: 1 !important;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use:hover .in-use-arrow, [window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use.hovering .in-use-arrow {
  visibility: visible;
  text-decoration: none !important;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .domain-in-use .in-use-arrow {
  visibility: hidden;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .loader {
  align-self: center;
  opacity: 0.6;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .save-subdomain {
  font-weight: 700;
  color: var(--baseColor-default);
  background: var(--baseColor-confirm);
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .save-subdomain svg {
  color: var(--baseColor-default);
  fill: var(--baseColor-default);
  height: 17px;
  width: auto;
  position: absolute;
  top: 12px;
  right: 9px;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .text-input.autosize .input-auto-size input {
  max-width: 329px;
}
[window-name=subscription-window] .uiWindow-content .domains .domain-card .text-input.autosize .input-auto-size {
  max-width: 367px;
}
[window-name=subscription-window] .uiWindow-content .domains .subdomains > .ui-group {
  grid-gap: 10px;
}
[window-name=subscription-window] .uiWindow-content .domains .subdomains > .ui-group .text-input.autosize .input-auto-size span {
  color: rgba(var(--baseColor-default), 0.85);
}