.ui-group .error .content-editable-input span.editable-value,
.ui-group .error .content-editable-input [placeholder]::before {
  color: var(--ui-color-error);
}
.ui-group .contact-form-details .content-editable-input {
  max-width: 267px;
}
.ui-group .content-editable-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 66.3%;
  -ms-flex: 1 0 66.3%;
  flex: 1 0 66.3%;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  min-height: var(--height-row);
  width: 100%;
  cursor: text;
  font-family: var(--fontFamily-default);
  font-size: 0;
  color: var(--ui-color-default);
  fill: var(--ui-color-default);
  background: var(--ui-background-default);
  text-size-adjust: 100%;
  padding-top: var(--ui-padding-default-vertical);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: var(--ui-padding-default-horizontal);
  padding-right: var(--ui-padding-default-horizontal);
  line-height: 0;
}
.ui-group .content-editable-input *,
.ui-group .content-editable-input *::before,
.ui-group .content-editable-input *::after {
  box-sizing: border-box;
}
.ui-group .content-editable-input span.content-editable {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-align: left;
  max-height: 25px;
  flex: 1;
}
.ui-group .content-editable-input span.content-editable.cargo-text-suffix span.editable-value:empty {
  width: auto;
}
.ui-group .content-editable-input span.content-editable.input-clear span.editable-value {
  width: 100%;
}
.ui-group .content-editable-input span.content-editable span.editable-value:empty {
  width: 100%;
}
.ui-group .content-editable-input span.content-editable span.editable-value {
  display: inline-block;
  line-height: var(--ui-lineHeight-default);
  font-size: var(--fontSize-default);
  min-width: 1px;
  min-height: 20px;
  width: auto;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: auto;
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
}
.ui-group .content-editable-input span.content-editable span.editable-value:empty {
  user-select: none;
}
.ui-group .content-editable-input span.content-editable [placeholder]:empty::before {
  content: attr(placeholder);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  font-size: var(--fontSize-default);
  line-height: var(--ui-lineHeight-default);
  display: inline-block;
  vertical-align: top;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ui-group .content-editable-input span.content-editable span.editable-value[placeholder]:empty {
  line-height: 2;
  font-size: 12px;
}
.ui-group .content-editable-input span.content-editable span.suggestion,
.ui-group .content-editable-input span.content-editable span.cargo-suffix-text,
.ui-group .content-editable-input span.content-editable span.placeholder-text {
  display: inline-block;
  font-size: var(--fontSize-default);
  line-height: var(--ui-lineHeight-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  width: auto;
  vertical-align: middle;
  white-space: nowrap;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-style: inherit;
  font-weight: inherit;
}
.ui-group .content-editable-input span.content-editable span.placeholder-text {
  width: auto;
}
.ui-group .content-editable-input .ellipsis {
  float: right;
  font-size: var(--fontSize-default);
  line-height: var(--ui-lineHeight-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  vertical-align: middle;
  font-style: inherit;
  font-weight: inherit;
}
.ui-group .content-editable-input input {
  display: none;
}