/* Base Color and Background Mixins */
body[cargo-jobs] #manager [job-page=all-jobs] {
  overflow-y: scroll;
}
body[cargo-jobs] #manager [job-page=all-jobs] .no-jobs {
  grid-column: 3/span 2;
  height: var(--height-row);
  display: flex;
  align-items: center;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list {
  grid-column: 1/-1;
  position: relative;
  height: calc(100% + var(--spacing-1));
  width: calc(100% + var(--spacing-1));
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list.my-listings .job-listing:active {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing:active:not(:has(.job-location:active, .employment-type:active)) {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing {
  display: inline-grid;
  position: relative;
  width: calc(100% - var(--spacing-1));
  height: var(--height-row);
  min-height: var(--height-row);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--grid-column-gap);
  grid-column: 3/-1;
  align-items: center;
  cursor: pointer;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .company-name,
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .job-title {
  color: var(--ui-color-default);
  line-height: var(--height-row);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .company-name {
  grid-column: 1/span 1;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .job-title {
  grid-column: 2/span 1;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location {
  grid-column: 3/span 1;
  color: var(--color-default-secondary);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .employment-type:not(.no-hover):hover,
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .job-location:not(.no-hover):hover {
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.25);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .employment-type:not(.no-hover):active,
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .job-location:not(.no-hover):active {
  opacity: var(--opacity-downstate-default);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location-separator {
  pointer-events: none;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--border-color);
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing.filler {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing.filler::after {
  display: none;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing > div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location {
  display: inline-flex;
  justify-content: flex-end;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .employment-type,
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .job-location {
  white-space: nowrap;
}
body[cargo-jobs] #manager [job-page=all-jobs] .job-list .job-listing .type-location .job-location {
  overflow: hidden;
  text-overflow: ellipsis;
}

body[cargo-jobs].authenticated #manager [job-page=all-jobs]::before {
  background: none;
}
body[cargo-jobs].authenticated #manager [job-page=all-jobs] .right-column::before {
  display: none;
}

body[cargo-jobs].mobile #manager [job-page=all-jobs] {
  grid-column: 1/-1;
}
body[cargo-jobs].mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing {
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
body[cargo-jobs].mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing .job-title {
  grid-column: 2/span 2;
}
body[cargo-jobs].mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing .company-name,
body[cargo-jobs].mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing .job-title {
  line-height: var(--lineHeight);
  padding-top: calc((var(--height-row) - var(--lineReturn-height)) * 0.5);
}
body[cargo-jobs].mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing .type-location {
  grid-column: 2/span 2;
  grid-row: 2;
  justify-content: flex-start;
  padding-bottom: calc((var(--height-row) - var(--lineReturn-height)) * 0.5);
}

body[cargo-jobs].authenticated.mobile #manager [job-page=all-jobs] .right-column .job-list .job-listing:first-child::before {
  display: none;
}