/* Base Color and Background Mixins */
.ui-group .radio-button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  position: relative;
  grid-gap: var(--element-gap);
}
.ui-group .disabled .radio-button-group .radio-button input[type=radio]:checked + label {
  background: var(--ui-background-default);
  color: var(--ui-color-default);
  fill: var(--ui-color-default);
}
.ui-group .radio-button {
  min-width: var(--height-row);
  height: var(--height-row);
  font-size: var(--fontSize-default);
}
.ui-group .radio-button label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--height-row);
  width: 100%;
  text-align: left;
  padding: var(--ui-padding-default);
  background: var(--ui-background-default);
  color: var(--ui-color-default);
  fill: var(--ui-color-default);
}
.ui-group .radio-button input[type=radio]:checked + label {
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
}
.ui-group .radio-button-group.text {
  grid-auto-columns: 1fr;
}
.ui-group .radio-button-group.text .radio-button label {
  position: relative;
  display: flex;
}
.ui-group .radio-cell-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 0fr;
  grid-gap: var(--element-gap);
  position: relative;
}
.ui-group .radio-cell-group.bars:before {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}
.ui-group .radio-cell-group .cell-label {
  height: var(--height-row);
  padding: var(--ui-padding-default);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  background: var(--ui-background-default);
}
.ui-group .radio-cell-group .radio-cells {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: var(--element-gap) var(--element-gap);
  grid-template-areas: ". . ." ". . ." ". . .";
}
.ui-group .radio-cell-group .radio-cells label {
  cursor: pointer;
  height: inherit;
  width: inherit;
  padding: 0;
}
.ui-group .radio-cell-group .radio-cells .radio-button {
  position: relative;
  margin: 0px;
  border: 0;
  padding: 0px;
  min-width: unset;
  height: unset;
}
.ui-group .thumbnail-button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  position: relative;
  grid-gap: var(--element-gap);
}
.ui-group .thumbnail-button-group .radio-button {
  width: unset;
  height: unset;
}
.ui-group .thumbnail-button-group .radio-button::before {
  content: " ";
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.ui-group .thumbnail-button-group .radio-button label {
  height: unset;
}
.ui-group .thumbnail-button-group .radio-button label svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ui-group .thumbnail-button-group .radio-button input[type=radio]:checked + label {
  background: var(--ui-background-default);
  outline: 2px solid var(--ui-background-accent);
  outline-offset: -2px;
}
.ui-group .text-radio.radio-button {
  height: auto;
  line-height: 1.3;
  width: fit-content;
}
.ui-group .text-radio.radio-button label {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  position: static;
  min-width: unset;
  padding: 0;
  background: none;
}
.ui-group .text-radio.radio-button input[type=radio]:checked + label {
  background: transparent;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}

.radio-button input[type=radio] {
  display: none;
}

.radio-button {
  position: relative;
}
.radio-button label {
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}