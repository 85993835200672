#admin input[type=checkbox],
#app input[type=checkbox] {
  -moz-appearance: initial;
}
#admin .checkbox,
#app .checkbox {
  --diameter-checkbox-circle: 22px;
  position: relative;
  cursor: pointer;
}
#admin .checkbox:active,
#app .checkbox:active {
  opacity: var(--opacity-downstate-default);
}
#admin .checkbox.disabled,
#app .checkbox.disabled {
  pointer-events: none;
  cursor: default;
}
#admin .ui-group .checkbox,
#app .ui-group .checkbox {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: var(--height-row);
  color: var(--ui-color-default);
  background-color: var(--ui-background-default);
  width: 100%;
  padding: var(--ui-padding-default);
  line-height: var(--ui-lineHeight-default);
  display: flex;
  align-items: center;
}
#admin .ui-group .checkbox .status-icon,
#app .ui-group .checkbox .status-icon {
  display: none;
  height: var(--height-row);
  width: var(--height-row);
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
#admin .ui-group .checkbox .status-icon svg,
#app .ui-group .checkbox .status-icon svg {
  position: unset;
  top: unset;
  right: unset;
  height: auto;
}
#admin .ui-group .checkbox .status-icon svg g,
#app .ui-group .checkbox .status-icon svg g {
  opacity: var(--ui-checkbox-inactive-opacity);
}
#admin .ui-group .checkbox[checked-status=true] .status-icon svg path.circle,
#app .ui-group .checkbox[checked-status=true] .status-icon svg path.circle {
  fill: var(--ui-color-accent);
}
#admin .ui-group .checkbox input[type=checkbox],
#app .ui-group .checkbox input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
#admin .ui-group .text-checkbox,
#app .ui-group .text-checkbox {
  max-width: fit-content;
  height: fit-content;
}
#admin .ui-group .text-checkbox .checkbox,
#app .ui-group .text-checkbox .checkbox {
  display: inline-flex;
  justify-content: flex-start;
  height: auto;
  width: auto;
  background: none;
  padding: 0;
  flex-direction: row-reverse;
  line-height: 1.3;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  position: static;
  min-width: unset;
  background: none;
}
#admin .ui-group .text-checkbox .checkbox[checked-status=true],
#app .ui-group .text-checkbox .checkbox[checked-status=true] {
  background: transparent;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
#admin .ui-group .text-checkbox .checkbox .status-icon,
#app .ui-group .text-checkbox .checkbox .status-icon {
  position: static;
  height: auto;
  width: auto;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.dark .ui-group .checkbox .status-icon svg g path {
  fill: white;
}