/* Base Color and Background Mixins */
button.badge {
  display: inline-flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 40px;
  height: 40px;
  padding: 5px !important;
}
button.badge.informational {
  pointer-events: none;
}
button.badge .icon {
  font-size: 32px;
  font-family: var(--fontFamily-default);
  content: "";
  border-radius: 100px;
  width: 26px;
  height: 26px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  text-decoration: none !important;
  pointer-events: none;
  line-height: 1;
}
button.badge .icon:active {
  opacity: 0.7;
}
button.badge .icon.filled {
  color: var(--background-2);
  box-shadow: 0 0 0 2px var(--background-2) !important;
  background: var(--background-2);
  pointer-events: none;
}
button.badge .icon.clickable {
  pointer-events: auto;
}
button.badge .icon[user-role=admin] {
  box-shadow: none;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}