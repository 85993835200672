/* Base Color and Background Mixins */
textarea {
  color: var(--ui-color-default);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
}
textarea::placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}

.ui-group .text-area {
  display: grid;
  padding: var(--ui-padding-default);
  background: var(--ui-background-default);
  position: relative;
}
.ui-group .text-area textarea {
  color: var(--ui-color-default);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  min-height: calc(var(--height-row-numeric) / 2 * 3 * 1px);
  background: var(--ui-background-default);
  border: none;
  margin: 0;
  padding: 0;
}

.dark textarea::placeholder {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}