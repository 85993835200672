.uiWindow[window-name=subscription-info-sticky][type=popover] {
  min-width: 315px;
  transform: none !important;
  top: 11vh !important;
  left: calc(50vw - 315px - 235px) !important;
  --sticky-bg: #fef49c;
}

.uiWindow[window-name=subscription-info-sticky][type=popover] {
  --windowRadiusSmall: 12px;
  --windowRadiusSmall-inner: calc(var(--windowRadiusSmall) - 1px);
  width: 293px;
  transform: none;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .sp {
  max-width: 2em;
  height: 2em;
  width: 2em;
  float: left;
  margin-right: 0.5em;
  transform: translate(0, 0.05em);
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .message-support {
  cursor: pointer;
  text-decoration: underline;
}
.uiWindow[window-name=subscription-info-sticky][type=popover][position=center] {
  bottom: 3rem;
  left: auto;
  top: auto;
  right: 10rem;
  position: fixed;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .close.close-uiWindow {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content {
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner {
  background: var(--sticky-bg);
  padding: 15px;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-size: var(--fontSize-default);
  font-weight: 400;
  font-family: var(--fontFamily-CargoDiatype);
  letter-spacing: 0;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .linked {
  text-decoration: underline;
  color: inherit;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .linked:active {
  opacity: 0.7;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner b {
  font-weight: 800;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .title {
  display: flex;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.1;
  font-style: italic;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .title.large {
  font-weight: 700;
  color: #D16044;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .title.large .emojicheck {
  font-style: normal;
  margin-right: 0.2em;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .legend {
  display: block;
  line-height: 1.3;
  margin-left: 20px;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .legend .legend-item {
  display: flex;
  align-items: center;
  margin-top: 0.1em;
}
.uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content .uiWindow-inner .legend .legend-item .icon {
  position: relative;
  display: inline-flex;
  margin-right: 0.1em;
}

.dark .uiWindow[window-name=subscription-info-sticky][type=popover] {
  --sticky-bg: var(--ui-hint-background);
}
.dark .uiWindow[window-name=subscription-info-sticky][type=popover] .uiWindow-content {
  background: none;
}