/* Base Color and Background Mixins */
button,
.button-link {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  padding: 0;
  text-align: left;
  white-space: nowrap;
  background: transparent;
  display: flex;
  margin: 0;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
button:not(.selected):active,
.button-link:not(.selected):active {
  opacity: var(--opacity-downstate-default);
}
button.more-button,
.button-link.more-button {
  padding: 7px;
  height: auto !important;
  line-height: 0 !important;
  border-radius: 1px;
  display: flex;
}
button.text-button em,
.button-link.text-button em {
  font-size: 1.45em;
  font-style: normal;
  line-height: 0;
  transform: translate(0px, 1px);
  display: inline-block;
  font-weight: 300;
}
button[button-style=rounded-1],
.button-link[button-style=rounded-1] {
  font-size: 12.5px;
  user-select: none;
  font-weight: 500;
  cursor: pointer;
  font-family: var(--fontFamily-default);
  letter-spacing: 0;
  width: unset;
  padding: 4.5px 12px 5.5px;
  border-radius: 3px;
  justify-content: flex-start;
  flex: 0 1 auto;
  text-align: center;
  line-height: 0.95;
  font-weight: 500;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  height: 22px;
  position: relative;
}
button[button-style=rounded-2],
.button-link[button-style=rounded-2] {
  flex: 1;
  text-align: center;
  padding: 8px;
  height: auto;
  line-height: 1;
  justify-content: center;
  border-radius: var(--ui-button-border-radius);
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
}
button[button-style=rounded-2].outlined,
.button-link[button-style=rounded-2].outlined {
  background: transparent !important;
  box-shadow: inset 0 0 0px 1px rgba(var(--baseColor-default-reverse-rgb), 0.2);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4) !important;
  padding: 4px 9.5px 5px;
}
button.duplicate, button.copy,
.button-link.duplicate,
.button-link.copy {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.75), #fff;
  background: var(--baseColor-accent);
}
button.copy + button.duplicate + button.edit, button.duplicate + button.edit, button.copy + button.edit,
button.copy + button.duplicate + .button-link.edit,
button.duplicate + .button-link.edit,
button.copy + .button-link.edit,
button.copy + .button-link.duplicate + button.edit,
.button-link.duplicate + button.edit,
.button-link.copy + button.edit,
button.copy + .button-link.duplicate + .button-link.edit,
.button-link.duplicate + .button-link.edit,
.button-link.copy + .button-link.edit,
.button-link.copy + button.duplicate + button.edit,
.button-link.copy + button.duplicate + .button-link.edit,
.button-link.copy + .button-link.duplicate + button.edit,
.button-link.copy + .button-link.duplicate + .button-link.edit {
  margin-left: 5px;
}
button[button-state=in-use],
.button-link[button-state=in-use] {
  fill: var(--baseColor-accent);
}
button[button-state=unavailable],
.button-link[button-state=unavailable] {
  cursor: default;
}
button[button-state=unavailable] span, button[button-state=unavailable] svg,
.button-link[button-state=unavailable] span,
.button-link[button-state=unavailable] svg {
  opacity: 0.25;
}
button[button-state=unavailable]:active,
.button-link[button-state=unavailable]:active {
  opacity: 1;
}
button[button-state=queued],
.button-link[button-state=queued] {
  fill: var(--ui-color-accent);
}
button.empty,
.button-link.empty {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.ui-group {
  /* Button group will always be even split w/ this setup */
}
.ui-group .upload-button svg {
  position: relative;
  top: unset;
  left: unset;
}
.ui-group .library-button {
  justify-content: flex-end;
}
.ui-group button,
.ui-group .button-link {
  display: grid;
  grid-auto-columns: 1fr auto;
  grid-auto-flow: column;
  position: relative;
  height: var(--height-row);
  padding: var(--ui-padding-default);
  color: var(--ui-color-default);
  background-color: var(--ui-background-default);
  width: 100%;
  text-align: left;
  line-height: var(--ui-lineHeight-default);
}
.ui-group button.text-button,
.ui-group .button-link.text-button {
  display: block;
  max-width: fit-content;
  height: fit-content;
  background: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  padding: 0;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
.ui-group button.text-button:active,
.ui-group .button-link.text-button:active {
  opacity: var(--opacity-downstate-default);
}
.ui-group button.text-button svg,
.ui-group .button-link.text-button svg {
  position: initial;
  height: auto;
}
.ui-group svg {
  position: absolute;
  top: 0;
  right: 0;
  height: var(--height-row);
}
.ui-group .button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  position: relative;
  grid-gap: var(--element-gap);
}
.ui-group .ellipsis-svg svg {
  height: 14px !important;
  width: 17px !important;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 0;
}
.ui-group button.bars:before {
  background: var(--ui-details-background);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  z-index: var(--layer-0);
}
.ui-group button.unclickable {
  pointer-events: none;
  cursor: default;
}
.ui-group button.bold {
  font-weight: bold;
}
.ui-group button.save-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc((var(--fixed-pane-width) - 1) * 1px);
  font-weight: bold;
  z-index: var(--layer-8);
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
  transition: transform 150ms ease-out;
  transform: translate(0, 100%);
  -webkit-transition: -webkit-transform 150ms ease-out;
  -webkit-transform: translate(0, 100%);
}
.ui-group button.save-button .loader {
  justify-self: center;
  align-self: center;
}
.ui-group button.save-button.initial {
  transform: translate(0, 0);
}
.ui-group button.label-button-group {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 30% auto;
  padding: 0;
  background: rgba(var(--baseColor-default-rgb), 1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  grid-gap: var(--element-gap);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
}
.ui-group button.label-button-group .button-label {
  height: var(--height-row);
  padding: var(--ui-padding-default);
  background: rgba(var(--baseColor-default-rgb), 0.8);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  position: relative;
  padding-right: 0;
  padding-top: calc(var(--ui-padding-default-vertical) - 1px);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: var(--ui-padding-default-horizontal);
  padding-right: var(--ui-padding-default-horizontal);
}
.ui-group button.label-button-group .button-label + div {
  color: var(--ui-color-default);
  padding-top: calc(var(--ui-padding-default-vertical) - 1px);
  padding-bottom: calc(var(--ui-padding-default-vertical) + 1px);
  padding-left: var(--ui-padding-default-horizontal);
  padding-right: var(--ui-padding-default-horizontal);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.ui-group button.label-button-group .button-label + div .cargo-text-suffix {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
.ui-group .icon-first {
  padding-left: calc(var(--height-row) + 3px);
}
.ui-group .icon-first svg {
  left: 0;
  right: initial;
}
.ui-group .save-cancel-buttons {
  --save-button-height: 28.5px;
  --save-button-width: 125px;
  align-items: center;
  justify-content: flex-end;
  height: var(--save-button-height);
  min-height: var(--save-button-height);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.ui-group .save-cancel-buttons button {
  justify-content: flex-start;
  border-radius: var(--ui-button-border-radius);
  flex: 0 1 var(--save-button-width);
  text-align: center;
  height: auto;
  line-height: 1;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  white-space: nowrap;
  display: flex;
  min-height: var(--save-button-height);
  position: relative;
}
.ui-group .save-cancel-buttons button.confirm {
  margin-right: 0;
  margin-left: 5px;
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
}
.ui-group .save-cancel-buttons button.cancel {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
.ui-group .save-cancel-buttons button .caution-processing {
  height: var(--save-button-height);
  width: 125px;
  border-radius: 100px;
}

.opt-buttons {
  display: flex;
  flex-direction: column;
  width: 240px;
}
.opt-buttons button {
  flex: 1;
  text-align: center;
  padding: 8px;
  height: auto;
  line-height: 1;
  justify-content: center;
}
.opt-buttons button.deny {
  border-radius: var(--ui-button-border-radius) 0 0 var(--ui-button-border-radius);
  background: rgba(var(--baseColor-default-reverse-rgb), 0.07);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
.opt-buttons button.confirm {
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
  border-radius: 0 var(--ui-button-border-radius) var(--ui-button-border-radius) 0;
  margin-left: var(--element-gap);
}

.notice .opt-buttons button.confirm {
  border-radius: var(--ui-button-border-radius) var(--ui-button-border-radius) var(--ui-button-border-radius) var(--ui-button-border-radius);
}

.uiWindow[type=fixed-pane] .ui-group button.label-button-group {
  max-width: calc((var(--fixed-pane-width) + 1) * 1px - var(--ui-padding-uiWindow) * 2);
}