body[cargo-jobs] #manager [job-page=job] .save-cancel-buttons {
  padding: calc((var(--lineReturn-height) * 2 - var(--fontSize-default) - 6.5px - 7.5px) * 0.5) 0;
  box-sizing: content-box;
  justify-content: flex-start;
}
body[cargo-jobs] #manager [job-page=job] .save-cancel-buttons button.confirm {
  margin-left: 0;
}
body[cargo-jobs] #manager [job-page=job] .right-column-inner-2 {
  grid-column: 3/span 4;
  overflow-y: scroll;
  padding-right: var(--spacing-1);
  padding-bottom: var(--spacing-1);
  margin-right: calc(var(--spacing-1) * -1);
  margin-bottom: calc(var(--spacing-1) * -1);
  padding-top: calc(var(--grid-cell-height) + var(--spacing-1));
  margin-top: calc((var(--grid-cell-height) + var(--spacing-1)) * -1);
}
body[cargo-jobs] #manager [job-page=job] .right-column-inner-2 .job-description {
  padding-right: calc(var(--grid-column-gap) + var(--grid-cell-width));
}

body[cargo-jobs].authenticated #manager [job-page=job]::before {
  display: none;
}
body[cargo-jobs].authenticated #manager [job-page=job] .right-column-inner-2 {
  overflow-y: hidden;
}
body[cargo-jobs].authenticated #manager [job-page=job] .right-column-inner-2 .job-description {
  padding-right: calc(var(--proxy-grid-cell-width) * 0.5);
}
body[cargo-jobs].authenticated #manager [job-page=job] .button-close {
  position: fixed;
  top: calc(var(--height-row) + 3px);
  right: 0px;
}

body[cargo-jobs].mobile #manager [job-page=job] {
  overflow-y: auto;
}
body[cargo-jobs].mobile #manager [job-page=job]::before {
  display: block;
}
body[cargo-jobs].mobile #manager [job-page=job] .left-column {
  grid-column: 1/span 2;
  grid-row: 2/span 1;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column {
  display: block;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column::before {
  left: 0;
  right: 0;
  width: 100%;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column .right-column-inner-1 {
  position: relative;
  max-width: unset;
  margin-bottom: var(--lineReturn-height);
  grid-column: 1/span 1;
  grid-row: 1/span 2;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column .right-column-inner-2 {
  margin: 0;
  margin-bottom: calc(var(--spacing-1) * -1);
  margin-right: calc(var(--spacing-1) * -1);
  padding: 0;
  padding-right: var(--spacing-1);
  grid-column: 1/-1;
  grid-row: 3;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column .right-column-inner-2::before {
  content: "";
  height: 1px;
  width: 100%;
  background: var(--border-color);
  position: absolute;
  top: 0;
  right: var(--spacing-1);
  z-index: 1;
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column .right-column-inner-2 .job-details {
  margin-right: var(--spacing-1);
}
body[cargo-jobs].mobile #manager [job-page=job] .right-column .right-column-inner-2 .job-details .job-description {
  padding-right: 0;
}

body[cargo-jobs].authenticated.mobile #manager [job-page=job] {
  margin-left: calc(var(--spacing-2) * -1);
  min-width: var(--viewport-width);
}
body[cargo-jobs].authenticated.mobile #manager [job-page=job] .left-column {
  grid-row: 1/span 1;
}
body[cargo-jobs].authenticated.mobile #manager [job-page=job] .right-column {
  margin-top: calc(var(--spacing-2) + var(--lineReturn-height) * 3);
}
body[cargo-jobs].authenticated.mobile #manager [job-page=job] .button-close {
  position: absolute;
  top: var(--close-button-offset);
  right: 0;
}