.uiWindow[window-name=dns-editor] {
  --c3-account-window-width: 780px;
  --windowHeaderHeight: 43px;
  --c3-account-button-width: 183.5px;
  --windowHeight: 60vh;
  overflow: visible;
  border-radius: var(--windowRadiusLarge);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight-default);
  height: var(--windowHeight);
  max-height: calc(100vh - var(--height-row) * 2);
  width: var(--c3-account-window-width);
  min-width: 556px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div.save-overflow {
  position: absolute;
  overflow: hidden;
  display: block;
  border: none;
  width: calc(var(--c3-account-window-width) - 2px);
  max-width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  height: auto;
  bottom: 1px;
  right: 1px;
  z-index: 9;
  pointer-events: none;
  padding-top: 1px;
  line-height: var(--lineHeight-default);
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons {
  border-radius: 0 0 14px 14px;
  overflow: hidden;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit {
  position: relative;
  border: none;
  height: auto;
  z-index: 9;
  pointer-events: none;
  padding: 15px;
  line-height: var(--lineHeight-default);
  display: flex;
  justify-content: space-between;
  background: var(--ui-background-container);
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit button.add-record,
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit button.who-is {
  background: var(--ui-background-default);
  color: var(--ui-color-default);
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit button.who-is {
  margin-left: 5px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: var(--legacyColor-grayscale-darker);
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit .save-cancel-buttons {
  visibility: hidden;
  pointer-events: none;
  transition: transform 0.15s ease-out;
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  -webkit-transform: translate(0, 100%);
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit .save-cancel-buttons.reverse {
  align-items: center;
  justify-content: flex-start;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit .save-cancel-buttons.transition-out {
  transform: translateY(155%) !important;
  -webkit-transform: translate(0, 155%) !important;
  pointer-events: none;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner div#dns-editor-buttons section.edit .save-cancel-buttons.visible {
  visibility: visible;
  transform: translate(0);
  -webkit-transform: translate(0, 0%);
  pointer-events: auto;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  grid-gap: 1px;
  min-width: 632px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row:hover button.more-button svg {
  opacity: 1;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row > :first-child {
  flex: 0 0 125px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row > :last-child {
  flex: 0 0 40px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row > :not(:first-child):not(:last-child) {
  flex: 1;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row.SRV > :nth-child(2) {
  flex: 0 0 110px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row.SRV > :nth-child(6) {
  flex: 0 0 200px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row.MX > :nth-child(2) {
  flex: 0 0 170px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row.MX > :nth-child(3) {
  flex: 0 0 170px;
}
.uiWindow[window-name=dns-editor] .uiWindow-content .uiWindow-inner .records-group.ui-group .record-list .record-row input::-webkit-contacts-auto-fill-button {
  visibility: hidden !important;
  pointer-events: none;
}

.uiWindow[window-name=whois-editor] {
  width: 400px;
}
.uiWindow[window-name=whois-editor] .uiWindow-content .uiWindow-inner {
  min-height: 361px;
}
.uiWindow[window-name=whois-editor] .uiWindow-content .uiWindow-inner .whois-form .label-input-group {
  grid-gap: 0;
}
.uiWindow[window-name=whois-editor] .uiWindow-content .uiWindow-inner .whois-form .label-input-group .input-label {
  background: var(--ui-background-default);
}

.uiWindow[window-name=dns-editor],
.uiWindow[window-name=whois-editor] {
  --windowHeaderHeight: 43px;
  --c3-account-button-width: 183.5px;
}
.uiWindow[window-name=dns-editor][type=popover] .close-uiWindow,
.uiWindow[window-name=whois-editor][type=popover] .close-uiWindow {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 12px;
}
.uiWindow[window-name=dns-editor][type=popover] .help-button,
.uiWindow[window-name=whois-editor][type=popover] .help-button {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 38px;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner {
  height: calc(var(--windowHeight) - 2px);
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner > .processing .loader,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner > .processing .loader {
  margin-top: 15px;
  margin-left: 15px;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner > .ui-group,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner > .ui-group {
  padding: 15px;
  width: auto;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-label,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-label {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-size: var(--fontSize-header);
  line-height: 1.4;
  display: flex;
  grid-gap: 0;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-label .variable-info-text,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-label .variable-info-text {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  user-select: all;
  pointer-events: all;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-label .variable-info-text .plain-url,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-label .variable-info-text .plain-url {
  max-width: 25ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content {
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  padding: 16px var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow);
  max-width: var(--c3-account-subview-width);
  height: 100%;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list {
  display: grid;
  grid-gap: 1px;
  padding-bottom: 120px;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list .select .select-display,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list .select .select-display {
  border-radius: 0;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button {
  height: auto;
  width: var(--height-row);
  padding: 0;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button svg,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button svg {
  position: relative;
  opacity: 0;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button svg *,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button svg * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button:hover svg, .uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button:active svg,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button:hover svg,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button:active svg {
  opacity: 1;
}
.uiWindow[window-name=dns-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button.hovering svg,
.uiWindow[window-name=whois-editor][type=popover] .uiWindow-content .uiWindow-inner window-content .records-group.ui-group .record-list button.more-button.hovering svg {
  opacity: 1;
}
.uiWindow[window-name=dns-editor] window-header,
.uiWindow[window-name=whois-editor] window-header {
  display: flex;
  position: sticky;
  top: 0px;
  left: 1px;
  border-bottom: 1px solid var(--legacyColor-grayscale-darker);
  font-family: var(--fontFamily-default);
  height: var(--windowHeaderHeight);
  min-height: var(--windowHeaderHeight);
  max-height: var(--windowHeaderHeight);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  margin: 0;
  z-index: 1;
  background: var(--ui-background-container);
  border-radius: var(--windowRadiusLarge) var(--windowRadiusLarge) 0 0;
}
.uiWindow[window-name=dns-editor] window-header .bolt,
.uiWindow[window-name=whois-editor] window-header .bolt {
  display: flex;
  height: 42px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
}
.uiWindow[window-name=dns-editor] window-header .bolt svg,
.uiWindow[window-name=whois-editor] window-header .bolt svg {
  height: 100%;
  margin: 0 auto;
}
.uiWindow[window-name=dns-editor] window-header window-label,
.uiWindow[window-name=whois-editor] window-header window-label {
  display: flex;
  margin: auto;
  line-height: 0.8;
  grid-gap: 4px;
  align-content: center;
  align-items: center;
  font-family: var(--fontFamily-default);
  font-size: 17px;
  font-weight: 400;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}