/* Base Color and Background Mixins */
.uiWindow[window-name=c3-account-manager] {
  --windowHeight: 80vh;
  --windowHeaderHeight: 43px;
  --c3-account-window-width: 580px;
  --c3-account-subview-width: 398px;
  --c3-account-button-width: 183.5px;
  --sub-highlight-background: #FF0;
  --PDF-RED: #f00;
  overflow: visible;
  border-radius: var(--windowRadiusLarge);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight-default);
  height: var(--windowHeight);
  max-height: calc(100vh - var(--height-row) * 2);
  top: 11vh;
  width: var(--c3-account-window-width);
  left: calc((100vw - var(--c3-account-window-width)) / 2);
  transform: none !important;
}
.uiWindow[window-name=c3-account-manager] window-header {
  display: flex;
  position: sticky;
  top: 0px;
  left: 1px;
  border-bottom: 1px solid var(--legacyColor-grayscale-darker);
  font-family: var(--fontFamily-default);
  height: var(--windowHeaderHeight);
  min-height: var(--windowHeaderHeight);
  max-height: var(--windowHeaderHeight);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  width: calc(var(--c3-account-window-width) - 2px);
  margin: 0;
  z-index: 1;
  background: var(--ui-background-container);
  border-radius: var(--windowRadiusLarge) var(--windowRadiusLarge) 0 0;
}
.uiWindow[window-name=c3-account-manager] window-header .bolt {
  display: flex;
  height: 42px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
}
.uiWindow[window-name=c3-account-manager] window-header .bolt svg {
  height: 100%;
  margin: 0 auto;
}
.uiWindow[window-name=c3-account-manager] window-header window-label {
  display: flex;
  margin: auto;
  line-height: 0.8;
  grid-gap: 4px;
  align-content: center;
  align-items: center;
  font-family: var(--fontFamily-default);
  font-size: 17px;
  font-weight: 400;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=c3-account-manager][type=popover] .close-uiWindow {
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 12px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner {
  height: calc(var(--windowHeight) - 2px);
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner > .processing .loader {
  margin-top: 15px;
  margin-left: 15px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner > .ui-group {
  padding: 15px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-label {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-size: var(--account-header-font-size);
  line-height: 1.4;
  display: inline-block;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 0px;
  padding: 0;
  height: 100%;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation {
  border-right: 1px solid var(--legacyColor-grayscale-darker);
  max-width: 180px;
  position: relative;
  line-height: 1.4;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .paths {
  width: 180px;
  padding-top: var(--ui-padding-default-horizontal);
  padding-left: var(--ui-padding-default-horizontal);
  padding-right: var(--ui-padding-default-horizontal);
  position: fixed;
  height: calc(var(--windowHeight) - var(--windowHeaderHeight));
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .path {
  display: inline-block;
  margin-bottom: 4px;
  width: 100%;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .path button {
  cursor: pointer;
  display: flex;
  flex: 1;
  width: fit-content;
  text-align: center;
  height: auto;
  line-height: 1;
  white-space: nowrap;
  justify-content: flex-start;
  border-radius: 4px;
  font-weight: 400;
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  padding: 4px 9px 5px;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  background: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .path button.active {
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
  width: 100%;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .path button.active:active {
  opacity: 1;
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .path button.active:active {
  opacity: 0.7;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .more-actions.footer {
  position: absolute;
  bottom: 3px;
  left: 0;
  padding: 0;
  cursor: default;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .more-actions.footer a.support-link {
  border-bottom: none !important;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  text-decoration: none;
  letter-spacing: 0px;
  line-height: var(--lineHeight-default);
  padding: 8px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .more-actions.footer a.support-link:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview {
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  padding: 16px var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow);
  max-width: var(--c3-account-subview-width);
  height: 100%;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-purchase-form {
  padding-top: calc(var(--ui-padding-uiWindow) + 15px);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .view-invoices {
  text-decoration: underline;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .payment-method-header {
  margin-bottom: 15px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .payment-method-header .variable-info-text {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  line-height: 1.1;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .payment-method-header .variable-info-text .plain-url {
  max-width: 36ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 3px;
  margin-bottom: -3px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details .payment-button .submit-payment {
  justify-content: flex-start;
  background: var(--baseColor-accent);
  border-radius: var(--subscription-rounded-button-radius);
  color: var(--baseColor-default);
  padding: 7px 18px 8px !important;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details hr {
  border: none;
  border-top: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.15);
  margin: 0 0;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details span.c2-upgrade-notice {
  color: var(--baseColor-accent);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details span.c2-upgrade-notice a.text-link {
  color: var(--baseColor-accent);
  text-decoration: underline;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details span.c2-upgrade-notice a.text-link:active {
  opacity: 0.7;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview [payment-action=changePaymentMethod] [button-style=rounded-2] {
  background: rgba(var(--baseColor-accent-rgb), 1);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
  height: auto;
  line-height: 1;
  border-radius: var(--ui-button-border-radius) !important;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal) !important;
  justify-content: flex-start;
  flex: 0 1 110px;
  min-width: 110px;
  width: auto;
  position: relative;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  text-align: left;
  white-space: nowrap;
  display: flex;
  margin: 0;
  height: 28px !important;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details .info {
  background: var(--baseColor-default);
  border-radius: 10px;
  padding: var(--ui-padding-uiWindow);
  line-height: 1.3;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details .info ul {
  padding: 0 0 0 2rem;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .subscription-details .info ul li {
  list-style-type: disc;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 1px;
  margin-top: 25px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card {
  border-radius: 0;
  min-height: calc(2em + var(--ui-padding-default) * 2);
  background: var(--baseColor-default);
  padding: var(--ui-padding-uiWindow);
  padding-top: 12px;
  padding-bottom: 14px;
  cursor: pointer;
  line-height: 1.4;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .top-row {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  grid-gap: 15px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .top-row .transaction-date {
  text-align: right;
  min-width: 12ch;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .top-row .transaction-amount {
  text-align: right;
  min-width: 5ch;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .bottom-row .transaction-additional {
  display: grid;
  grid-template-columns: auto 1fr;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .bottom-row .transaction-additional .domain-name-column {
  max-width: 35ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: italic;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .bottom-row .transaction-additional .domain-price-column {
  text-align: right;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card .bottom-row .transaction-additional .icon-match {
  font-style: normal;
  visibility: hidden;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview .invoices .invoice-card:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .account-settings-form {
  font-size: var(--fontSize-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .account-settings-form .label-input-group {
  grid-template-columns: 31% auto;
  grid-gap: 0px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .account-settings-form .label-input-group .input-label {
  background: var(--baseColor-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  white-space: nowrap;
  overflow: hidden;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .account-settings-form .label-input-group input {
  font-size: var(--fontSize-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .account-settings-form .label-input-group.error input {
  color: var(--subscription-error-color);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .error-message {
  position: relative;
  flex: 1 1;
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--subscription-error-color);
  font-size: var(--fontSize-default);
  line-height: var(--ui-lineHeight-default);
  padding-bottom: var(--ui-padding-default-vertical);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .error-message .alert-text {
  padding-left: 30px;
  color: var(--subscription-error-color);
  font-size: var(--fontSize-default);
  line-height: var(--ui-lineHeight-default);
  white-space: nowrap;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .error-message .alert-icon {
  height: var(--height-row);
  width: var(--height-row);
  position: absolute;
  left: -10px;
  top: -10px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account .error-message .alert-icon svg {
  position: relative;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div.save-overflow {
  position: absolute;
  overflow: hidden;
  display: block;
  border: none;
  width: var(--c3-account-subview-width);
  height: auto;
  bottom: 1px;
  right: 0;
  z-index: 9;
  pointer-events: none;
  padding-top: 1px;
  line-height: var(--lineHeight-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div#save-buttons section.save {
  position: relative;
  display: block;
  border: none;
  width: var(--c3-account-subview-width);
  height: auto;
  z-index: 9;
  pointer-events: none;
  padding: 15px;
  line-height: var(--lineHeight-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div#save-buttons section.save .save-cancel-buttons {
  visibility: hidden;
  pointer-events: none;
  transition: transform 0.15s ease-out;
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  -webkit-transform: translate(0, 100%);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div#save-buttons section.save .save-cancel-buttons.transition-out {
  transform: translateY(155%) !important;
  -webkit-transform: translate(0, 155%) !important;
  pointer-events: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div#save-buttons section.save .save-cancel-buttons.visible {
  visibility: visible;
  transform: translate(0);
  -webkit-transform: translate(0, 0%);
  pointer-events: auto;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-account div#save-buttons section.save .save-cancel-buttons:before {
  content: " ";
  position: absolute;
  top: -15px;
  left: -16px;
  height: 1px;
  width: var(--c3-account-subview-width);
  background-color: var(--legacyColor-grayscale-darker);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.parent + .subcription-addons {
  padding-top: 10px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription > .ui-window-text {
  margin-top: 0px;
  line-height: 1.4em;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .ui-group .ui-window-text:last-of-type, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .ui-group .ui-window-text:last-of-type {
  margin-top: var(--ui-padding-uiWindow) !important;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domains-list, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domains-list {
  margin-bottom: 6rem;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card {
  margin-bottom: 1px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card {
  margin-bottom: 10px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card:last-child, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card:last-child {
  margin-bottom: 0;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card {
  display: grid;
  grid-template-columns: auto 0.1fr;
  grid-gap: 1px;
  position: relative;
  line-height: 1.4;
  max-width: var(--max-subscription-card-width);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.hosted-domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.hosted-domain, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.hosted-domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.hosted-domain {
  display: flex;
  background: var(--baseColor-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.hosted-domain .info-grid .info.type .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.hosted-domain .info-grid .info.type .variable-info-text .plain-url, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.hosted-domain .info-grid .info.type .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.hosted-domain .info-grid .info.type .variable-info-text .plain-url {
  max-width: unset;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .info-grid:first-child .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .info-grid:first-child .info, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .info-grid:first-child .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .info-grid:first-child .info {
  line-height: var(--lineHeight-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .info-grid:first-child .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .info-grid:first-child .info, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .info-grid:first-child .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .info-grid:first-child .info {
  line-height: 1.4;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.editing, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.noDetailsMenu,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.editing,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.noDetailsMenu, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.editing, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.noDetailsMenu,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.editing,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.noDetailsMenu {
  grid-template-columns: auto;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card:last-child,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card:last-child, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card:last-child,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card:last-child {
  margin-bottom: 0;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .domain-subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .domain-subscription-info, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .domain-subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .domain-subscription-info {
  padding-top: 10px;
  padding-bottom: 12px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .domain-subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card.multi-line .domain-subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .domain-subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card.multi-line .domain-subscription-info .info-grid.subscription, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .domain-subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card.multi-line .domain-subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .subscription-info .info-grid.subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .domain-subscription-info .info-grid.domain,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card.multi-line .domain-subscription-info .info-grid.subscription {
  margin-bottom: 5px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info::nth-child(2),
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info::nth-child(2), .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info::nth-child(2),
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info::nth-child(2) {
  margin-top: var(--subscription-inner-padding);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info::nth-child(2),
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info::nth-child(2), .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info::nth-child(2),
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info::nth-child(2) {
  margin-top: var(--subscription-inner-padding);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info {
  display: block;
  background: var(--baseColor-default);
  padding: var(--ui-padding-default);
  align-items: center;
  max-width: var(--max-subscription-info-width);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info-grid, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info-grid,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info-grid {
  display: grid;
  grid-template-columns: auto 0.1fr;
  grid-gap: 1px;
  width: 100%;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.in-use-arrow-link, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.in-use-arrow-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.in-use-arrow-link {
  display: inline-flex;
  cursor: pointer;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .in-use-arrow-link:active, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .in-use-arrow-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .in-use-arrow-link:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .in-use-arrow-link a:active, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .in-use-arrow-link a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .in-use-arrow-link a:active {
  opacity: 1 !important;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info:hover .info .in-use-arrow, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info.hovering .info .in-use-arrow, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info:hover .info .in-use-arrow, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info.hovering .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info:hover .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info.hovering .info .in-use-arrow {
  visibility: visible;
  text-decoration: none !important;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .in-use-arrow, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .in-use-arrow,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .in-use-arrow {
  visibility: hidden;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info {
  display: flex;
  line-height: 1.4;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  text-decoration: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .variable-info-text, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .variable-info-text,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .variable-info-text {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .variable-info-text .plain-url, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .variable-info-text .plain-url,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .variable-info-text .plain-url {
  max-width: 25ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .in-use, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .in-use,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .in-use {
  margin-right: 10px;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .in-use a, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .in-use a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .in-use a {
  text-decoration: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info .in-use a:hover, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info .in-use a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info .in-use a:hover {
  text-decoration: none;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.edit-dns-link, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.edit-dns-link,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.edit-dns-link {
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
  cursor: pointer;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.edit-dns-link:active, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.edit-dns-link:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.edit-dns-link:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.price, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.price {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.warning, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.warning,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.warning {
  color: var(--subscription-error-color);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.warning span, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.warning span,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.warning span {
  color: var(--subscription-error-color);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info a, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info a,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info a {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  cursor: pointer;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info a:hover, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info a:hover,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info a:hover {
  text-decoration: underline;
  text-decoration-color: rgba(var(--baseColor-default-reverse-rgb), 0.3);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info a:active, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info a:active,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info a:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.type, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.type,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.type {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info .info.parent-subscription, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .subscription-info .info.parent-subscription,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info .info.parent-subscription {
  padding: var(--ui-padding-default);
  line-height: var(--ui-lineHeight-default);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .domain-subscription-info::last-child,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .domain-subscription-info::last-child, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .domain-subscription-info::last-child,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .domain-subscription-info::last-child {
  margin-bottom: var(--subscription-inner-padding);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card button.more-button,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card button.more-button, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card button.more-button,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card button.more-button {
  height: auto;
  width: var(--height-row);
  padding: 0;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card button.more-button svg,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card button.more-button svg, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card button.more-button svg,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card button.more-button svg {
  position: relative;
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card button.more-button svg *,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card button.more-button svg *, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card button.more-button svg *,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card button.more-button svg * {
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .subscription-card .num-item-price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription .domain-subscription-card .num-item-price, .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .subscription-card .num-item-price,
.uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-domains .domain-subscription-card .num-item-price {
  grid-template-columns: auto 0.1fr;
}
.uiWindow[window-name=c3-account-manager] div.caution-processing {
  z-index: 1;
}
.uiWindow[window-name=c3-account-manager] .back-button {
  cursor: pointer;
  display: inline-flex;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  font-family: var(--fontFamily-default);
  line-height: var(--lineHeight-default);
  padding-top: calc(var(--ui-padding-uiWindow) * 2);
  z-index: 1;
}
.uiWindow[window-name=c3-account-manager] .back-button:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=c3-account-manager] .right-arrow {
  padding: 0;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  font-size: 20px;
  line-height: 38px;
}
.uiWindow[window-name=c3-account-manager] .billed-to-editor {
  --textAreaPopoverMaxHeight: 120px;
  --textAreaPopoverMaxWidth: 320px;
  position: absolute;
  top: 105px;
  right: -30px;
  padding: 1px;
  background: var(--ui-window-border-background);
  max-height: var(--textAreaPopoverMaxHeight);
  height: var(--textAreaPopoverMaxHeight);
  width: var(--textAreaPopoverMaxWidth);
  border-radius: var(--windowRadiusLarge);
  box-shadow: var(--window-boxShadow-default);
  border-radius: var(--windowRadiusLarge);
}
.uiWindow[window-name=c3-account-manager] .billed-to-editor .inner {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 0;
  background-color: var(--ui-background-container);
  position: relative;
  background: var(--ui-background-default);
  height: calc(var(--textAreaPopoverMaxHeight) - 2px);
  min-height: calc(var(--textAreaPopoverMaxHeight) - 2px);
  border-radius: var(--windowRadiusLarge-inner);
}
.uiWindow[window-name=c3-account-manager] .billed-to-editor .inner div.text-area {
  display: grid;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  border-radius: var(--windowRadiusLarge);
  background: var(--ui-background-default);
  padding: 10px 12px;
}
.uiWindow[window-name=c3-account-manager] .billed-to-editor .inner div.text-area textarea {
  min-height: 100%;
  max-height: 100px;
  resize: none;
  min-width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--ui-color-default);
  background: var(--ui-background-default);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
}

body.dark .uiWindow[window-name=c3-account-manager] {
  --sub-highlight-background: var(--ui-hint-background);
}

body.light span.c2-upgrade-notice {
  --baseColor-accent: #0033ff;
}

.mobile .uiWindow[window-name=c3-account-manager] {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  --c3-account-window-width: 400px;
}
.mobile .uiWindow[window-name=c3-account-manager] window-header {
  width: auto;
}
.mobile .uiWindow[window-name=c3-account-manager] window-label .subview-label {
  display: none;
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content {
  display: flex;
  flex-direction: column;
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content > * {
  min-height: fit-content;
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation {
  border-right: none;
  border-bottom: 1px solid var(--legacyColor-grayscale-darker);
  max-width: 100%;
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .navigation .paths {
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: var(--ui-padding-default-horizontal);
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview {
  flex: 1;
}
.mobile .uiWindow[window-name=c3-account-manager][type=popover] .uiWindow-content .uiWindow-inner window-content .data-subview.subview-subscription {
  height: unset;
}