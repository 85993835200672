.ui-group .override-reset {
  content: "";
  position: absolute;
  top: calc(50% + var(--ui-padding-uiWindow) / 2);
  left: calc(var(--ui-padding-uiWindow) * -1);
  cursor: pointer !important;
  width: var(--ui-padding-uiWindow);
  height: var(--ui-padding-uiWindow);
  transform: translate(0px, -100%);
  display: block;
  line-height: 0;
  padding: 0;
  background: transparent;
}
.ui-group .override-reset svg {
  transform: translate(0.25px, -0.25px);
  pointer-events: none;
  width: var(--ui-padding-uiWindow);
  height: var(--ui-padding-uiWindow);
}
.ui-group .override-reset svg rect {
  fill: var(--ui-background-accent);
  stroke: var(--ui-background-accent);
}
.ui-group .override-reset:active {
  opacity: var(--opacity-downstate-default);
}