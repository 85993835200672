/* Base Color and Background Mixins */
.uiWindow[window-name=subscription-window],
.uiWindow[window-name=c3-account-manager] {
  --subscription-letter-spacing: 0;
  --subscription-inner-padding: 10px;
  --subscription-button-height: 28.5px;
  --subscription-select-border-radius: var(--ui-button-border-radius);
  --subscription-rounded-button-radius: var(--ui-button-border-radius);
  --subscription-button-width: 100%;
  --subscription-font-size-large: 18px;
  --account-header-font-size: var(--fontSize-header);
  --subscription-ui-bar-bg: var(--ui-details-background);
  --subscription-error-color: var(--ui-color-error);
}

.uiWindow[window-name=subscription-window],
.uiWindow[window-name=subscription-info-sticky] {
  --windowHeight: 73vh;
  --subscription-window-width: 400px;
  --subscription-subview-width: 400px;
}

.uiWindow[window-name=subscription-window] {
  border-radius: var(--windowRadiusLarge);
  font-size: var(--fontSize-default);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  line-height: var(--lineHeight-default);
  height: var(--windowHeight);
  top: 11vh;
  width: var(--subscription-window-width);
  left: calc((100vw - var(--subscription-window-width)) / 2);
  transform: none !important;
}
.uiWindow[window-name=subscription-window].wide {
  --subscription-window-width: 780px;
}
.uiWindow[window-name=subscription-window][type=popover] .close-uiWindow {
  z-index: 11;
  top: 12px;
  right: 12px;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner {
  height: calc(var(--windowHeight) - 2px);
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner > .processing .loader {
  margin-top: 15px;
  margin-left: 15px;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner > .ui-group {
  padding: 15px;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner .success-message {
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
  font-size: var(--account-header-font-size);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner .success-message .emoji {
  margin-bottom: 10px;
  display: inline-block;
  line-height: 1;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner .error-message {
  line-height: 1.3;
  margin-top: 30px;
  text-align: center;
  font-size: var(--account-header-font-size);
  color: var(--ui-color-error);
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-label {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-size: var(--account-header-font-size);
  line-height: 1.4;
  display: inline-block;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content {
  padding: 0 var(--ui-padding-uiWindow);
  height: 100%;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content:not(.info-frame) > div {
  flex: 1;
  padding: var(--ui-padding-uiWindow) 0;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content .sp {
  max-width: 2em;
  height: 2em;
  width: 2em;
  float: left;
  margin-right: 0.5em;
  transform: translate(0, 0.175em);
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame {
  display: flex;
  grid-gap: 15px;
  flex-direction: row;
  overflow: hidden;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame > div {
  flex: 1;
  padding: var(--ui-padding-uiWindow) 0;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .purchase-form,
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .domains {
  overflow: scroll;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .purchase-form::-webkit-scrollbar,
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .domains::-webkit-scrollbar {
  display: none;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .pricing-iframe iframe {
  height: 100%;
  width: 100%;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content.info-frame .domain-search {
  padding-bottom: 80px;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content .more-actions {
  cursor: default;
}
.uiWindow[window-name=subscription-window][type=popover] .uiWindow-content .uiWindow-inner window-content .more-actions span {
  cursor: pointer;
}
.uiWindow[window-name=subscription-window] window-header {
  display: flex;
  position: sticky;
  top: 0px;
  left: 1px;
  border-bottom: 1px solid var(--legacyColor-grayscale-darker);
  font-family: var(--fontFamily-default);
  height: 43px;
  min-height: 43px;
  max-height: 43px;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  width: calc(var(--subscription-window-width) - 2px);
  margin: 0;
  z-index: 10;
  background: var(--ui-background-container);
  border-radius: var(--windowRadiusLarge) var(--windowRadiusLarge) 0 0;
}
.uiWindow[window-name=subscription-window] window-header window-label {
  display: flex;
  margin: auto;
  line-height: 0.8;
  grid-gap: 4px;
  align-content: center;
  align-items: center;
  font-family: var(--fontFamily-default);
  font-size: 17px;
  font-weight: 400;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=subscription-window] window-header window-label .logo-box {
  width: 40px;
  height: 44px;
  position: absolute;
  left: -1px;
  top: -1px;
}
.uiWindow[window-name=subscription-window] window-header window-label .logo-box .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.uiWindow[window-name=subscription-window] window-header window-label .logo-box .logo svg {
  margin: 0;
}
.uiWindow[window-name=subscription-window] div.caution-processing {
  z-index: 1;
}
.uiWindow[window-name=subscription-window] .back-button {
  cursor: pointer;
  display: inline-flex;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  position: absolute;
  top: 10px;
  left: 14px;
  z-index: 10;
  font-size: 20px;
  line-height: 22px;
}
.uiWindow[window-name=subscription-window] .back-button:active {
  opacity: 0.7;
}
.uiWindow[window-name=subscription-window] .right-arrow {
  padding: 0;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  font-size: 20px;
  line-height: 38px;
}

.dark .uiWindow[window-name=subscription-window],
.dark .uiWindow[window-name=c3-account-manager] {
  --agreement-text-color: rgba(255, 255, 255, 0.75);
}