.tool-tip {
  --tooltip-arrow-size: 6px;
  --tooltip-background: var(--baseColor-default);
  --tooltip-color: rgba(var(--baseColor-default-reverse-rgb),.85);
  position: absolute;
  display: flex;
  text-align: left;
  line-height: var(--lineHeight-default);
  padding: 1px;
  background: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  border-radius: 2px;
  z-index: var(--layer-9);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tool-tip .tooltip-content {
  background: var(--tooltip-background);
  color: var(--tooltip-color);
  font-size: var(--fontSize-small);
  border-radius: 1px;
  padding: 0 4px;
  opacity: 0.9;
}
.tool-tip span {
  line-height: 1.2;
  padding: 3px 0px;
}
.tool-tip span em {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.55);
  font-weight: normal;
  font-style: normal;
  margin-left: 1ch;
  letter-spacing: 0.1em;
}
.tool-tip span em span {
  letter-spacing: 0;
}
.tool-tip .shortcut {
  margin-left: 10px;
}
.tool-tip .shortcut:empty {
  display: none;
}
.tool-tip.right.corner:before {
  height: 0;
  width: 0;
  display: none;
}
.tool-tip.hidden {
  visibility: hidden;
  display: none;
}
.tool-tip.measure {
  visibility: hidden;
  display: flex;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}
.tool-tip.visible {
  visibility: visible;
  display: flex;
}