/* Base Color and Background Mixins */
.help-button.absolute {
  position: absolute;
  top: 15px;
  right: 15px;
}
.help-button.absolute-right {
  position: absolute;
  right: 0;
}
.help-button .help-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.help-button .help-icon span {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
  background: transparent;
  pointer-events: none;
  border-radius: 15px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  outline: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.3);
  outline-offset: -1px;
}