/* Base Color and Background Mixins */
body {
  --divider-width: .5px;
  --grid-color-1: #ff827b;
  --grid-color-2: rgba(203, 227, 251, .25);
  --grid-color-3: #a3c991;
  --grid-color-4: rgba(203, 227, 251, 1);
}
body.dark #dev-grid-layer {
  mix-blend-mode: invert;
}

button.grid-toggle {
  z-index: 1000;
  position: fixed;
  left: var(--spacing-2);
  bottom: var(--spacing-2);
  width: fit-content;
  padding: var(--spacing-half);
  line-height: 1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

#dev-grid {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  display: grid;
  grid-template-rows: var(--grid-system-rows);
  grid-row-gap: 0px;
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 9998;
  padding: var(--spacing-1);
  mix-blend-mode: multiply;
  min-height: var(--viewport-height);
}
#dev-grid > div {
  position: relative;
  overflow: hidden;
}
#dev-grid .one {
  grid-column: 1/span 5;
  grid-row: 1/span 5;
}
#dev-grid .two {
  grid-column: var(--grid-line-golden)/span 3;
  grid-row: 3/span 3;
}
#dev-grid .three {
  grid-column: 7/span 2;
  grid-row: 1/span 2;
}
#dev-grid .four {
  grid-column: var(--grid-line-golden)/span 1;
  grid-row: 1/span 1;
}

#dev-grid-layer {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  display: grid;
  grid-template-rows: var(--grid-system-rows);
  grid-row-gap: 0px;
  position: fixed;
  min-height: var(--viewport-height);
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999;
  padding: var(--divider-width);
  border: calc(var(--spacing-1) - var(--divider-width)) solid white;
  mix-blend-mode: multiply;
}
#dev-grid-layer .cell {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--grid-subcolumn-gap);
  background: var(--grid-color-2);
  margin: var(--divider-width);
  outline: 1px solid var(--grid-color-1);
}
#dev-grid-layer .cell .sub-cell {
  background: white;
  outline: 1px dotted var(--grid-color-4);
}
#dev-grid-layer .cell .sub-cell:nth-child(1), #dev-grid-layer .cell .sub-cell:nth-child(2), #dev-grid-layer .cell .sub-cell:nth-child(3) {
  margin-top: 0;
}
#dev-grid-layer .cell .sub-cell:nth-child(1), #dev-grid-layer .cell .sub-cell:nth-child(4), #dev-grid-layer .cell .sub-cell:nth-child(7) {
  margin-left: 0;
}
#dev-grid-layer .cell .sub-cell:nth-child(3), #dev-grid-layer .cell .sub-cell:nth-child(6), #dev-grid-layer .cell .sub-cell:nth-child(9) {
  margin-right: 0;
}
#dev-grid-layer .cell .sub-cell:nth-child(7), #dev-grid-layer .cell .sub-cell:nth-child(8), #dev-grid-layer .cell .sub-cell:nth-child(9) {
  margin-bottom: 0;
}

body.mobile #dev-grid-layer .cell.desktop {
  display: none;
}