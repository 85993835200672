.uiWindow[window-name=subscription-window],
.uiWindow[window-name=c3-account-manager] {
  --max-subscription-card-width: calc( var(--c3-account-subview-width) - calc( var(--ui-padding-uiWindow) * 2 ) );
  --max-subscription-info-width: calc( var(--max-subscription-card-width) - var(--height-row) );
  --max-subscription-info-text-width: calc( var(--max-subscription-info-width) - calc( var(--ui-padding-default-horizontal) * 2 ) - var(--height-row) - 5px );
}
.uiWindow[window-name=subscription-window] .section-header,
.uiWindow[window-name=c3-account-manager] .section-header {
  line-height: 1;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
}
.uiWindow[window-name=subscription-window] .purchase-form .first-payment-radio-group + .first-payment-radio-options,
.uiWindow[window-name=c3-account-manager] .purchase-form .first-payment-radio-group + .first-payment-radio-options {
  margin-top: 10px;
}
.uiWindow[window-name=subscription-window] .purchase-form .first-payment-radio-options + *:not(.ui-group),
.uiWindow[window-name=c3-account-manager] .purchase-form .first-payment-radio-options + *:not(.ui-group) {
  margin-top: 15px;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-header,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-header,
.uiWindow[window-name=subscription-window] .subscription-info .payment-header,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-header,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-header,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-header {
  margin-top: calc(var(--ui-padding-uiWindow));
}
.uiWindow[window-name=subscription-window] .purchase-form .require-payment-details,
.uiWindow[window-name=subscription-window] .subview-payment-methods .require-payment-details,
.uiWindow[window-name=subscription-window] .subscription-info .require-payment-details,
.uiWindow[window-name=c3-account-manager] .purchase-form .require-payment-details,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .require-payment-details,
.uiWindow[window-name=c3-account-manager] .subscription-info .require-payment-details {
  margin-top: 10px;
  margin-bottom: 15px;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-container .transfer-back-button,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-container .transfer-back-button,
.uiWindow[window-name=subscription-window] .subscription-info .payment-container .transfer-back-button,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-container .transfer-back-button,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-container .transfer-back-button,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-container .transfer-back-button {
  position: relative;
  padding-top: 0;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-container .transfer-back-button .text-link,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-container .transfer-back-button .text-link,
.uiWindow[window-name=subscription-window] .subscription-info .payment-container .transfer-back-button .text-link,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-container .transfer-back-button .text-link,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-container .transfer-back-button .text-link,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-container .transfer-back-button .text-link {
  padding-top: 0;
  text-decoration: none;
  width: auto;
}
.uiWindow[window-name=subscription-window] .purchase-form .save-cancel-buttons,
.uiWindow[window-name=subscription-window] .subview-payment-methods .save-cancel-buttons,
.uiWindow[window-name=subscription-window] .subscription-info .save-cancel-buttons,
.uiWindow[window-name=c3-account-manager] .purchase-form .save-cancel-buttons,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .save-cancel-buttons,
.uiWindow[window-name=c3-account-manager] .subscription-info .save-cancel-buttons {
  justify-content: flex-start;
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-hint,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-hint,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-hint,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-hint,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-hint,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-hint {
  margin-bottom: 15px;
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-hint .window-link,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-hint .window-link,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-hint .window-link,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-hint .window-link,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-hint .window-link,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-hint .window-link {
  cursor: pointer;
}
.uiWindow[window-name=subscription-window] .purchase-form .transfer-select,
.uiWindow[window-name=subscription-window] .subview-payment-methods .transfer-select,
.uiWindow[window-name=subscription-window] .subscription-info .transfer-select,
.uiWindow[window-name=c3-account-manager] .purchase-form .transfer-select,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .transfer-select,
.uiWindow[window-name=c3-account-manager] .subscription-info .transfer-select {
  margin-top: 0px;
}
.uiWindow[window-name=subscription-window] .purchase-form .transfer-select + .uiWindow-spacer,
.uiWindow[window-name=subscription-window] .subview-payment-methods .transfer-select + .uiWindow-spacer,
.uiWindow[window-name=subscription-window] .subscription-info .transfer-select + .uiWindow-spacer,
.uiWindow[window-name=c3-account-manager] .purchase-form .transfer-select + .uiWindow-spacer,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .transfer-select + .uiWindow-spacer,
.uiWindow[window-name=c3-account-manager] .subscription-info .transfer-select + .uiWindow-spacer {
  margin-top: 0;
  height: 30px;
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card span,
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card .domain-text,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item .domain-text,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item .domain-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card .domain-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item .domain-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item .domain-text,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card span,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card .domain-text,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item .domain-text,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item .domain-text,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card span,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card .domain-text,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item .domain-text,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item .domain-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card .domain-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item .domain-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item .domain-text,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card span,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card .domain-text,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item .domain-text,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item .domain-text {
  line-height: 1.3;
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card span span,
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card .domain-text span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item span span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item .domain-text span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item span span,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item .domain-text span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card span span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card .domain-text span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item span span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item .domain-text span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item span span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item .domain-text span,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card span span,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card .domain-text span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item span span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item .domain-text span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item span span,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card span span,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card .domain-text span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item span span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item span span,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card span span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card .domain-text span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item span span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item span span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card span span,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card .domain-text span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item span span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item .domain-text span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item span span,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item .domain-text span {
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  max-width: var(--max-subscription-info-text-width);
}
.uiWindow[window-name=subscription-window] .purchase-form .purchase-card .subtext,
.uiWindow[window-name=subscription-window] .purchase-form .domain-addon-item .subtext,
.uiWindow[window-name=subscription-window] .purchase-form .domain-name-item .subtext,
.uiWindow[window-name=subscription-window] .subview-payment-methods .purchase-card .subtext,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-addon-item .subtext,
.uiWindow[window-name=subscription-window] .subview-payment-methods .domain-name-item .subtext,
.uiWindow[window-name=subscription-window] .subscription-info .purchase-card .subtext,
.uiWindow[window-name=subscription-window] .subscription-info .domain-addon-item .subtext,
.uiWindow[window-name=subscription-window] .subscription-info .domain-name-item .subtext,
.uiWindow[window-name=c3-account-manager] .purchase-form .purchase-card .subtext,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-addon-item .subtext,
.uiWindow[window-name=c3-account-manager] .purchase-form .domain-name-item .subtext,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .purchase-card .subtext,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-addon-item .subtext,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .domain-name-item .subtext,
.uiWindow[window-name=c3-account-manager] .subscription-info .purchase-card .subtext,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-addon-item .subtext,
.uiWindow[window-name=c3-account-manager] .subscription-info .domain-name-item .subtext {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.4);
}
.uiWindow[window-name=subscription-window] .purchase-form .full-domain,
.uiWindow[window-name=subscription-window] .subview-payment-methods .full-domain,
.uiWindow[window-name=subscription-window] .subscription-info .full-domain,
.uiWindow[window-name=c3-account-manager] .purchase-form .full-domain,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .full-domain,
.uiWindow[window-name=c3-account-manager] .subscription-info .full-domain {
  font-style: italic;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
}
.uiWindow[window-name=subscription-window] .purchase-form .full-domain .plain-url,
.uiWindow[window-name=subscription-window] .subview-payment-methods .full-domain .plain-url,
.uiWindow[window-name=subscription-window] .subscription-info .full-domain .plain-url,
.uiWindow[window-name=c3-account-manager] .purchase-form .full-domain .plain-url,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .full-domain .plain-url,
.uiWindow[window-name=c3-account-manager] .subscription-info .full-domain .plain-url {
  max-width: 28ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.uiWindow[window-name=subscription-window] .purchase-form span.price-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods span.price-text,
.uiWindow[window-name=subscription-window] .subscription-info span.price-text,
.uiWindow[window-name=c3-account-manager] .purchase-form span.price-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods span.price-text,
.uiWindow[window-name=c3-account-manager] .subscription-info span.price-text {
  line-height: 1.3;
}
.uiWindow[window-name=subscription-window] .purchase-form .who-is .whois-input-form,
.uiWindow[window-name=subscription-window] .subview-payment-methods .who-is .whois-input-form,
.uiWindow[window-name=subscription-window] .subscription-info .who-is .whois-input-form,
.uiWindow[window-name=c3-account-manager] .purchase-form .who-is .whois-input-form,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .who-is .whois-input-form,
.uiWindow[window-name=c3-account-manager] .subscription-info .who-is .whois-input-form {
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 0.5fr;
  grid-gap: var(--element-gap);
  position: relative;
  grid-template-columns: repeat(2, 1fr);
}
.uiWindow[window-name=subscription-window] .purchase-form .who-is .whois-input-form .text-input::after,
.uiWindow[window-name=subscription-window] .subview-payment-methods .who-is .whois-input-form .text-input::after,
.uiWindow[window-name=subscription-window] .subscription-info .who-is .whois-input-form .text-input::after,
.uiWindow[window-name=c3-account-manager] .purchase-form .who-is .whois-input-form .text-input::after,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .who-is .whois-input-form .text-input::after,
.uiWindow[window-name=c3-account-manager] .subscription-info .who-is .whois-input-form .text-input::after {
  outline: none;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button {
  display: flex;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.submit-payment,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.submit-payment,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.submit-payment,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.submit-payment,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.submit-payment,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.submit-payment {
  justify-content: flex-start;
  background: var(--baseColor-accent);
  border-radius: var(--subscription-rounded-button-radius);
  flex: 0 1 var(--subscription-button-width);
  color: #fff;
  text-align: center;
  height: auto;
  line-height: 0;
  padding: 7px 18px 8px !important;
  align-items: center;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  white-space: nowrap;
  display: flex;
  min-height: var(--height-row);
  position: relative;
  min-width: var(--subscription-button-width);
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.submit-payment.pro-rated,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.submit-payment.pro-rated,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.submit-payment.pro-rated,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.submit-payment.pro-rated,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.submit-payment.pro-rated,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.submit-payment.pro-rated {
  min-width: 100%;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.submit-payment.pro-rated span,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.submit-payment.pro-rated span,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.submit-payment.pro-rated span,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.submit-payment.pro-rated span,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.submit-payment.pro-rated span,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.submit-payment.pro-rated span {
  display: inline-flex;
  width: 100%;
  line-height: 1;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.submit-payment.pro-rated span .right,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.submit-payment.pro-rated span .right,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.submit-payment.pro-rated span .right,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.submit-payment.pro-rated span .right,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.submit-payment.pro-rated span .right,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.submit-payment.pro-rated span .right {
  text-align: right;
  flex: 1;
  max-width: 26ch;
  position: absolute;
  display: inline;
  right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: -2px 0;
  padding: 2px 0;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.submit-payment .caution-processing {
  border-radius: var(--subscription-rounded-button-radius);
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.processing-transaction,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.processing-transaction,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.processing-transaction,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.processing-transaction,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.processing-transaction,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.processing-transaction {
  pointer-events: none;
  user-select: none;
  cursor: pointer;
}
.uiWindow[window-name=subscription-window] .purchase-form .payment-button button.processing-transaction:active,
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-button button.processing-transaction:active,
.uiWindow[window-name=subscription-window] .subscription-info .payment-button button.processing-transaction:active,
.uiWindow[window-name=c3-account-manager] .purchase-form .payment-button button.processing-transaction:active,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-button button.processing-transaction:active,
.uiWindow[window-name=c3-account-manager] .subscription-info .payment-button button.processing-transaction:active {
  opacity: 1;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder {
  position: relative;
  pointer-events: auto;
  height: 40px;
  width: 100%;
  overflow: hidden;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder:active .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder:active .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder:active .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder:active .paypal-button-cover {
  background: #fafaf9;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder:active .paypal-button-cover svg,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover svg,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder:active .paypal-button-cover svg,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder:active .paypal-button-cover svg,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover svg,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder:active .paypal-button-cover svg {
  opacity: 0.7;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover {
  height: 40px;
  width: 100%;
  background: var(--ui-background-default);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  pointer-events: none;
  font-weight: 700;
  border-radius: 0;
  padding-left: 12px;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover .light-cover,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover .light-cover,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover .light-cover,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover .light-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover .light-cover,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover .light-cover {
  display: block;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover .dark-cover,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover .dark-cover,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover .dark-cover,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover .dark-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover .dark-cover,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover .dark-cover {
  display: none;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover .paypal-button-cover-text {
  transform: translate(0px, -1px);
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover img,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover img,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover img {
  width: auto;
  height: 20px;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio label,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio label,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio label,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio label,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio label,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio label {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio label .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio label .paypal-button-cover,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio label .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio label .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio label .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio label .paypal-button-cover {
  height: 40px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: none;
  font-weight: 700;
  border-radius: 0;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio label .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio label .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio label .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio label .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio label .paypal-button-cover .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio label .paypal-button-cover .paypal-button-cover-text {
  transform: translate(0px, -1px);
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio label .paypal-button-cover img,
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio label .paypal-button-cover img,
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio label .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio label .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio label .paypal-button-cover img,
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio label .paypal-button-cover img {
  width: auto;
  height: 20px;
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#003087"],
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#003087"],
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#003087"],
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#003087"],
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#003087"],
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#003087"] {
  fill: rgba(255, 255, 255, 0.8);
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"],
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"],
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"],
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"],
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"],
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#0070e0"] {
  fill: rgba(255, 255, 255, 0.6);
}
.uiWindow[window-name=subscription-window] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"],
.uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"],
.uiWindow[window-name=subscription-window] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"],
.uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"],
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"],
.uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio input[type=radio]:checked + label svg [fill="#001c64"] {
  fill: rgb(255, 255, 255);
}
.uiWindow[window-name=subscription-window] .creditcard-form div.input-frame,
.uiWindow[window-name=c3-account-manager] .creditcard-form div.input-frame {
  height: 40px;
  background: var(--baseColor-default);
  overflow: hidden;
}
.uiWindow[window-name=subscription-window] .select + .creditcard-form,
.uiWindow[window-name=c3-account-manager] .select + .creditcard-form {
  margin-top: 0px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr;
  display: block;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-header,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-header {
  margin-top: 0;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-list,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-list {
  display: grid;
  grid-gap: 1px;
  padding-bottom: calc(var(--ui-padding-uiWindow) * 2);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-list .payment-method-label,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-list .payment-method-label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--baseColor-default);
  height: var(--height-row);
  padding: var(--ui-padding-default);
  padding-right: 0px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method {
  cursor: pointer;
  height: var(--height-row);
  width: var(--height-row);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method:active,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method:active {
  opacity: var(--opacity-downstate-default);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method svg,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .payment-methods-list .payment-method-label .remove-method svg {
  pointer-events: none;
  right: 12px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .radio-button-group,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .radio-button-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  position: relative;
  grid-gap: var(--element-gap);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method {
  display: grid;
  grid-gap: 1px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method.hide-cc-module .creditcard-form, .uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method.hide-cc-module .payment-button,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method.hide-cc-module .creditcard-form,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method.hide-cc-module .payment-button {
  display: none;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .payment-button,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .payment-button {
  padding-bottom: 40px;
  max-width: 125px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .payment-button button.submit-payment,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .payment-button button.submit-payment {
  justify-content: flex-start;
  background: var(--baseColor-accent);
  color: var(--baseColor-accent-reverse);
  border-radius: var(--ui-button-border-radius);
  flex: 0 1 var(--save-button-width);
  text-align: center;
  height: auto;
  line-height: 1;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px !important;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  white-space: nowrap;
  display: flex;
  min-height: var(--save-button-height);
  position: relative;
  width: 125px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .payment-button button.submit-payment .caution-processing,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .payment-button button.submit-payment .caution-processing {
  height: var(--save-button-height);
  width: 125px;
  border-radius: var(--ui-button-border-radius);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .creditcard-form .uiWindow-spacer,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .creditcard-form .uiWindow-spacer {
  height: 10px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .cc-button-svg svg,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .cc-button-svg svg {
  right: 9px;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .paypal-button-holder,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .paypal-button-holder {
  border-radius: 0;
  margin-top: 0;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .paypal-button-holder .paypal-button-cover,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .paypal-button-holder .paypal-button-cover {
  background: var(--ui-background-default);
  border-radius: 0;
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .paypal-button-holder .paypal-button-cover svg,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .paypal-button-holder .paypal-button-cover svg {
  transform: translate(-163px, 0px);
}
.uiWindow[window-name=subscription-window] .subview-payment-methods .add-payment-method .paypal-button-cover-text,
.uiWindow[window-name=c3-account-manager] .subview-payment-methods .add-payment-method .paypal-button-cover-text {
  opacity: 0;
}

.dark .uiWindow[window-name=subscription-window] .purchase-form .paypal-radio.checked label .paypal-button-cover img,
.dark .uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-radio.checked label .paypal-button-cover img,
.dark .uiWindow[window-name=subscription-window] .subscription-info .paypal-radio.checked label .paypal-button-cover img,
.dark .uiWindow[window-name=c3-account-manager] .purchase-form .paypal-radio.checked label .paypal-button-cover img,
.dark .uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-radio.checked label .paypal-button-cover img,
.dark .uiWindow[window-name=c3-account-manager] .subscription-info .paypal-radio.checked label .paypal-button-cover img {
  filter: invert(0.875);
}
.dark .uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder:active .paypal-button-cover,
.dark .uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover,
.dark .uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder:active .paypal-button-cover,
.dark .uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder:active .paypal-button-cover,
.dark .uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder:active .paypal-button-cover,
.dark .uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder:active .paypal-button-cover {
  background: #262623;
}
.dark .uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover .light-cover,
.dark .uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover .light-cover,
.dark .uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover .light-cover,
.dark .uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover .light-cover,
.dark .uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover .light-cover,
.dark .uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover .light-cover {
  display: none;
}
.dark .uiWindow[window-name=subscription-window] .purchase-form .paypal-button-holder .paypal-button-cover .dark-cover,
.dark .uiWindow[window-name=subscription-window] .subview-payment-methods .paypal-button-holder .paypal-button-cover .dark-cover,
.dark .uiWindow[window-name=subscription-window] .subscription-info .paypal-button-holder .paypal-button-cover .dark-cover,
.dark .uiWindow[window-name=c3-account-manager] .purchase-form .paypal-button-holder .paypal-button-cover .dark-cover,
.dark .uiWindow[window-name=c3-account-manager] .subview-payment-methods .paypal-button-holder .paypal-button-cover .dark-cover,
.dark .uiWindow[window-name=c3-account-manager] .subscription-info .paypal-button-holder .paypal-button-cover .dark-cover {
  display: block;
}