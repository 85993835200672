button.text-button {
  display: block;
  max-width: fit-content;
  height: fit-content;
  background: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  padding: 0;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
button.text-button:active {
  opacity: var(--opacity-downstate-default);
}
button.text-button svg {
  position: initial;
  height: auto;
}